<template>
    <div>
        <Pumpkin v-if="$can('theme.halloween') && halloween" style="margin-top: 70px; margin-left: 65px;"></Pumpkin>
        <label class="sidebar-label pd-x-10 mg-t-20">Navigation</label>
        <ul class="br-sideleft-menu">
            <router-link to="/" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isExactActive && 'router-link-active active router-link-exact-active']">
                        <span class="menu-item-label">Dashboard</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('forms-view') && $store.state.auth.user.selectedClient !== -1" to="/createclaim" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Create a Claim</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('forms-view') && $store.state.auth.user.selectedClient != -1" to="/evidence" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Manage Claims</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            
            <router-link v-if="$can('events-view') && $store.state.auth.user.selectedClient != -1" to="/events" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Events</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('blacklist-view') && $store.state.auth.user.selectedClient != -1" to="/blacklist" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Blacklist</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>

            <router-link v-if="$can('reports-view')" to="/reports" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Reports</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
        </ul>

        <label class="sidebar-label pd-x-10 mg-t-25" v-if="$can('claims-view') && $store.state.auth.user.selectedClient !== -1">Claims</label>
        <ul class="br-sideleft-menu" v-if="$can('claims-view') && $store.state.auth.user.selectedClient !== -1">
            <router-link to="/claims/management" v-if="$can('claims-view') && $store.state.auth.user.selectedClient !== -1" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Process Claims</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link to="/reminders" v-if="$can('claims-view') && $store.state.auth.user.selectedClient !== -1" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">My Reminders</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link to="/vehiclesearch" v-if="$can('vehicles-locate')" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Vehicle Search</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link to="/checker" v-if="$can('dvla.view') && $store.state.auth.user.selectedClient !== -1" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Enquiry Checker</span>
                    </a>
                </div>
            </router-link>
            <router-link v-if="$can('claims-process') && $can('dvla-view') && $store.state.auth.user.selectedClient !== -1" to="/scanned" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Scanned Documents</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('claims-process') && $can('dvla-view') && $store.state.auth.user.selectedClient !== -1" to="/commontransfers" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Common Transfers</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('claims-process') && $can('dvla-view') && $store.state.auth.user.selectedClient !== -1" to="/cancellations/admin" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Cancellations ({{cancellations}})</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('claims-process') && $can('claims-search') && $store.state.auth.user.selectedClient !== -1" to="/claimlocator" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Advanced Search</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('claims-process') && $store.state.auth.user.selectedClient !== -1" to="/calls" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Call Recordings</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('claims.popla.read')" to="/popla" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">POPLA</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('complaints-view')" to="/complaints" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Complaints</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            <router-link v-if="$can('claims-process')" to="/vehiclesar" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Vehicle SAR</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
            
        </ul>

        <label class="sidebar-label pd-x-10 mg-t-25" v-if="$can('claims-manage-team') && $store.state.auth.user.selectedClient !== -1">Manage</label>
        <ul class="br-sideleft-menu" v-if="$can('claims-manage-team') && $store.state.auth.user.selectedClient !== -1">
            <router-link to="/templates/appeals" v-slot="{href, route, navigate, isActive, isExactActive}">
                <div class="br-menu-item">
                    <a class="br-menu-link" :href="href" @click="navigate" :class="[isActive && 'router-link-active active', isExactActive && 'router-link-exact-active']">
                        <span class="menu-item-label">Appeal Templates</span>
                    </a><!-- br-menu-link -->
                </div><!-- menu-item -->
            </router-link>
        </ul>

        <p style="margin-top: 20px; margin-left: 15px;">v2024-10-21 110000</p>

    </div>
</template>

<script>
import Pumpkin from '@/components/Pumpkin.vue';
export default {
    name: 'Sidebar',
    components: {
        Pumpkin
    },
    props: {
        cancellations: Number | String,
        halloween: Boolean
    },
    data(){
        return {
            menu: {
                driveOff: {
                    show: false,
                },
                nmop: {
                    show: false,
                },
                parking: {
                    show: false,
                },
                manageClaims: {
                    show: false,
                },
                whitelist: {
                    show: false,
                },
                queues: {
                    show: false
                },
                debtcollection: {
                    show: false
                },
                nbcs: {
                    show: false
                }
            },
        }
    },
    methods: {
        closeAllDropdowns(){
            for(let menu in this.menu){
                if(this.menu.hasOwnProperty(menu)){
                    let actualItem = this.menu[menu];
                    actualItem.show = false;
                }
            }
        },
        toggleNbcs(){
            if(!this.menu.nbcs.show){
                this.closeAllDropdowns();
            }
            this.menu.nbcs.show = !this.menu.nbcs.show;
        },
        toggleDebtCollection(){
            if(!this.menu.debtcollection.show){
                this.closeAllDropdowns();
            }
            this.menu.debtcollection.show = !this.menu.debtcollection.show;
        },
        toggleWhitelist(){
            if(!this.menu.whitelist.show){
                this.closeAllDropdowns();
            }
            this.menu.whitelist.show = !this.menu.whitelist.show;
        },
        toggleDriveoff(){
            if(!this.menu.driveOff.show){
                this.closeAllDropdowns();
            }
            this.menu.driveOff.show = !this.menu.driveOff.show;
        },
        toggleQueues(){
            if(!this.menu.queues.show){
                this.closeAllDropdowns();
            }
            this.menu.queues.show = !this.menu.queues.show;
        },
        toggleNmop(){
            if(!this.menu.nmop.show){
                this.closeAllDropdowns();
            }
            this.menu.nmop.show = !this.menu.nmop.show;
        },
        toggleParking(){
            if(!this.menu.parking.show){
                this.closeAllDropdowns();
            }
            this.menu.parking.show = !this.menu.parking.show;
        },
        toggleManageClaims(){
            this.closeAllDropdowns();
            this.menu.manageClaims.show = !this.menu.manageClaims.show;
        },
    }
}
</script>