<template>
    <div>
        <!-- ########## START: LEFT PANEL ########## -->
        <div :class="{'sb-closed-logo': !sidebar}" class="br-logo justify-content-center">
            <img style="width: 50%; margin-right: auto; margin-left: auto; height: auto; cursor: pointer;" @click="$router.push('/')" src="/img/VARS_Logo.png">
        </div>
        <div :class="{'sb-closed': !sidebar}" class="br-sideleft sideleft-scrollbar">
            <div class="form-group mg-t-10" v-if="$store.state.auth.user.clients.length > 0">
                <select :disabled="!$store.state.showClientSelector" v-model="$store.state.auth.user.selectedClient" class="form-control select2" v-on:change="clientChanged" v-select2>
                    <option value="-1">No Client</option>
                    <option v-for="client in sortedClients" v-bind:value="client.id">{{client.name}}</option>
                </select>
            </div>

            <p class="text-danger" v-show="!$store.state.showClientSelector">Please make sure you close all open claims before attempting to switch client.</p>

            <Sidebar :user="user" :cancellations="cancellationRequests" :halloween="halloween"></Sidebar>

        </div>
        <!-- ########## END: LEFT PANEL ########## -->

        <!-- ########## START: HEAD PANEL ########## -->
        <div :class="{'sb-closed-header': !sidebar}" class="br-header">
            <div class="br-header-left">
                <div class="navicon-left hidden-md-down"><a id="btnLeftMenu" href="javascript:void(0);" @click="setSidebar(!sidebar)"><i class="icon ion-navicon-round"></i></a></div>
                <div class="navicon-left hidden-lg-up"><a id="btnLeftMenuMobile" href="javascript:void(0);" @click="setSidebar(!sidebar)"><i class="icon ion-navicon-round"></i></a></div>
                <div v-if="$can('sites-view') || $canAll('claims-view', 'claims-process')" class="input-group hidden-xs-down wd-350 transition">
                    <div class="input-group-prepend">
                        <select class="form-control" v-model="searchType">
                            <option v-if="$can('sites-view') && $can('claims-manage-team')" value="site">Site</option>
                            <option v-if="$can('claims-view') && $can('claims-process')" value="claim">Claim</option>
                        </select>
                    </div>
                    <input :class="{'red-ph': !$store.state.showClientSelector}" v-model="siteSearch" :disabled="!$store.state.showClientSelector" @keyup="globalSearch" @focus="showSiteList = true;" @blur="hideSiteList" id="searchbox" type="text" class="form-control" :placeholder="$store.state.showClientSelector ? 'Search' : 'Close open claims to access'">
                    <span class="input-group-btn">
                        <button class="btn btn-secondary" type="button"><i class="fa fa-search"></i></button>
                    </span>
                </div>
                <p v-if="!$store.state.auth.user.email.includes('@varstechnology.co.uk') && !$store.state.auth.user.email.includes('@ads-europe.co.uk')" class="tx-white tx-bold mg-l-20" style="margin-top: auto; margin-bottom: auto;">Support Email: <a class="tx-white vars-link-support" href="mailto:support@varstechnology.co.uk?subject=VARS Support Request">support@varstechnology.co.uk</a></p>
            </div>
            <div class="br-header-right">
                <nav class="nav">
                    <div v-if="phone" class="dropdown" v-bind:class="{'show': showPhoneMenu}">
                        <a href="javascript:void(0);" @click="showPhoneModal" class="nav-link nav-link-profile">
                            <span class="logged-name hidden-md-down badge badge-pill" :class="{'badge-danger': !this.phone.ready, 'badge-success': this.phone.ready, 'badge-warning': this.phone.onCall}">
                                Phone: {{ phoneStatus }}
                            </span>
                        </a>
                    </div>
                    <div v-if="phone" class="dropdown">
                        <a v-if="!phone.started" href="javascript:void(0);" @click="phone.startUp" class="nav-link nav-link-profile">
                            <span class="logged-name hidden-md-down badge badge-pill badge-success" >
                                Enable Phone
                            </span>
                        </a>

                        <a v-if="phone.started && phone.onCall" href="javascript:void(0);" @click="phone.hangUp" class="nav-link nav-link-profile">
                            <span class="logged-name hidden-md-down badge badge-pill badge-danger" >
                                End Call
                            </span>
                        </a>
                    </div>
                    <div class="dropdown" v-bind:class="{'show': showUserMenu}">
                        <a href="javascript:void(0);" @click="toggleShowUserMenu" class="nav-link nav-link-profile" data-toggle="dropdown">
                            <span class="logged-name hidden-md-down badge badge-primary badge-pill">
                                {{user.email}}
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-header wd-250" v-bind:class="{'show': showUserMenu}">
                            <div class="tx-center">
                                <h6 class="logged-fullname">{{user.email}}</h6>
                            </div>
                            <hr>
                            <ul class="list-unstyled user-profile-nav">
                                <li><a href="javascript:void(0);"><i class="fas fa-circle" v-bind:class="{'tx-success': $store.state.auth.user.token.length > 0, 'tx-danger': $store.state.auth.user.token.length < 1}"></i> API Connection</a></li>
                                <li><a href="javascript:void(0);" @click="logout"><i class="fas fa-power-off"></i> Sign Out</a></li>
                            </ul>
                            <div v-if="$can('theme.halloween')" class="form-group">
                                <label class="form-label">Halloween Theme</label>
                                <div class="custom-control custom-switch">
                                    <input :value="halloween" @click="toggleHalloween" type="checkbox" class="custom-control-input" id="halloweenSwitch">
                                    <label class="custom-control-label" for="halloweenSwitch">Enable Halloween Theme</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <!-- ########## END: HEAD PANEL ########## -->

        <!-- ########## START: MAIN PANEL ########## -->
        <div :class="{'sb-closed-main': !sidebar}" class="br-mainpanel">

            <div class="br-pagebody">

                <div class="w-100 h-100 mg-t-20" id="loadedContent">

                    <div v-if="$can('theme.halloween') && halloween" class="snowflakes">
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" />   
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                      <div class="snowflake">
                        <img src="https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif" /> 
                      </div>
                    </div>

                    <br/>
                    <router-view :key="$route.fullPath"></router-view>

                        <div v-show="showSiteList" class="dropdown-menu dropdown-menu-header wd-350 show" x-placement="bottom-start" :style="`position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(${sidebar ? '289px' : '60px'}, 48px, 0px); z-index: 10000;`">
                            
                            <ul v-if="searchType === 'site' && $can('claims-manage-team')" class="list-group list-group-striped">
                                <li v-for="site in sites" :key="'sitelist-' + site.id" style="cursor: pointer;" @click="selectSite(site)" class="list-group-item rounded-top-0">
                                    <p class="mg-b-0"><strong class="tx-white tx-medium">({{site.reference}}) {{site.name}}</strong> <span class="tx-info">{{site.client_name}}</span></p>
                                </li>
                            </ul>

                            <ul v-if="searchType === 'claim'" class="list-group list-group-striped">
                                <li v-for="claim in claims" :key="'claimlist-' + claim.reference" style="cursor: pointer;" @click="selectClaim(claim)" class="list-group-item rounded-top-0">
                                    <p class="mg-b-0"><strong class="tx-white tx-medium">{{claim.reference}}</strong> <span class="tx-info">{{claim.client_name}}</span></p>
                                </li>
                            </ul>

                        </div>

                </div>

            </div>

        </div>
        <!-- ########## END: MAIN PANEL ########## -->

        <!-- PHONE SETTINGS MODAL -->
        <div id="phoneSettingsModal" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Phone Settings</h6>
                        <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div v-if="phone" class="modal-body bg-dark tx-white pd-20">

                        <div class="form-group">
                            <label for="exampleInputEmail1">Microphone</label>
                            <select id="ringtone-devices" v-model="phone.inputDevice" @change="phone.updateInputDevice" class="form-control">
                                <option v-for="device in phone.ringtoneDevices" :value="device.value">{{device.label}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Speaker</label>
                            <select id="speaker-devices" v-model="phone.outputDevice" @change="phone.updateOutputDevice" class="form-control">
                                <option v-for="device in phone.speakerDevices" :value="device.value">{{device.label}}</option>
                            </select>
                        </div>

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button id="closePhoneSettingsModal" type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Phone Notes -->
        <div v-if="phone?.onCall" class="phone-number-pad">
            <div class="row">
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(7)">7</button>
                </div>
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(8)">8</button>
                </div>
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(9)">9</button>
                </div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(4)">4</button>
                </div>
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(5)">5</button>
                </div>
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(6)">6</button>
                </div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(1)">1</button>
                </div>
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(2)">2</button>
                </div>
                <div class="col-4">
                    <button class="btn btn-dark" @click="phone.sendDigits(3)">3</button>
                </div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-6">
                    <button class="btn btn-dark" @click="phone.sendDigits('#')">#</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-dark" @click="phone.sendDigits(0)">0</button>
                </div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-12">
                    <button class="btn btn-danger" @click="phone.hangUp">Hang Up</button>
                </div>
            </div>
        </div>
        <textarea v-if="phone?.onCall" class="phone-notes-container form-control" id="phone-notes" rows="10" v-model="phone.notes"></textarea>

    </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import authHeader from '../../services/auth-header';
import Socket from '../../services/socket';
import Phone from '../../services/phone';
import axios from 'axios';

export default {
    components: {
    Sidebar
}, 
    data(){
        return {
            sidebar: true,
            showUserMenu: false,
            showPhoneMenu: false,
            user: {},
            cancellationRequests: 0,
            searchType: 'claim',
            siteSearch: '',
            showSiteList: false,
            sites: [],
            claims: [],
            counter: 0,
            socket: null,
            phone: null,
            halloween: false,
            cancellationsInterval: null
        }
    },
    beforeUnmount(){
        clearInterval(this.cancellationsInterval);
    },
    mounted(){
        let halloweenSettings = localStorage.getItem('halloween');
        if(halloweenSettings){
            this.halloween = halloweenSettings == 1 ? true : false;
        }
        $(".select2").select2();
        this.user = this.$store.state.auth.user;
        this.$store.state.auth.sidebar = this.sidebar;
        this.socket = new Socket(this.$store.state.auth.user.ws_url);
        if(this.$can('calls-view') || this.$can('calls-listen')){
            this.phone = new Phone();
            this.phone.on('ready', () => {
                this.$nextTick(() => {
                    console.info("Phone ready!");
                })
            });
            this.phone.on('call-accepted', () => {
                this.$nextTick(() => {
                    document.getElementById('phone-notes').focus();
                });
            })
        }
        this.cancellationsInterval = setInterval(() => {
            
            if(!this.$can('claims-process')) return;
            axios.get(`https://api.varsanpr.com/api/cancellations/count`, {
                headers: authHeader()
            })
            .then(response => {
                this.cancellationRequests = response.data.totalRequests;
            })
            .catch(() => {

            });

        }, 30000);

        let query = this.$route.fullPath.split('?');
        if(query.length > 1){
            let params = query[1].split('&');
            for(let i = 0; i < params.length; i++){
                let param = params[i].split('=');
                if(param[0] === 'referenceRedirect'){
                    this.siteSearch = param[1];
                    this.globalSearch(true);
                }
            }
        }
    },
    methods: {
        toggleHalloween(){
            this.halloween = !this.halloween;
            localStorage.setItem('halloween', this.halloween ? 1 : 0);
        },  
        showPhoneModal(){
            $('#phoneSettingsModal').modal('toggle');
        },      
        setSidebar(value){
            this.sidebar = value;
            this.$store.state.auth.sidebar = value;
        },
        hideSiteList(){
            setTimeout(() => {
                this.showSiteList = false;
            }, 1000);
        },
        globalSearch(selectFirst = false){
            if(this.searchType === 'site') this.searchSites(selectFirst);
            if(this.searchType === 'claim') this.searchClaims(selectFirst);
        },
        searchClaims(selectFirst = false){
            this.counter++;
            axios.get(`https://api.varsanpr.com/api/claims/all?reference=${this.siteSearch}&counter=${this.counter}`, {
                headers: authHeader()
            })
            .then(response => {
                if(this.counter == response.data.counter){
                    this.$nextTick(() => {
                        this.claims = response.data.claims;
                        if(selectFirst && this.claims.length > 0) this.selectClaim(this.claims[0]);
                    })
                }
            })
            .catch(error => {
                this.$error("Failed to list claims", error);
            })
        },
        searchSites(){
            this.counter++;
            axios.get(`https://api.varsanpr.com/api/sites/all?search=${this.siteSearch}&counter=${this.counter}`, {
                headers: authHeader()
            })
            .then(response => {
                if(this.counter == response.data.counter){
                    this.$nextTick(() => {
                        this.sites = response.data.sites;
                    })
                }
            })
            .catch(error => {
                this.$error("Failed to list sites", error);
            })
        },
        selectSite(site){
            console.log("Selecting site: " + site.name);
            window.open('https://admin.varsanpr.com/sites/' + site.id, '_blank');
            
        },
        selectClaim(claim){
            console.log("Selecting claim: " + claim.reference);
            this.user.selectedClient = claim.client_id;
            axios.patch(`https://api.varsanpr.com/api/account/changeclient`, {
                client_id: this.user.selectedClient
            }, {
                headers: authHeader()
            })
            .then(response => {
                console.log(`Client has changed. New Client is: #${this.user.selectedClient} ${this.user.clients.filter((c) => c.id === this.user.selectedClient)[0].name}`);
                this.$store.dispatch('auth/selectClient', this.user.clients.filter((c) => c.id === this.user.selectedClient)[0]).then(() => {
                    this.$router.push({name: 'claims', query: {reference: claim.reference}, params: {reference: claim.reference}}).catch(() => {});
                })
                
            })
            .catch(error => {
                console.log(error);
                this.$error("Failed to update selected client");
            });
        },
        clientChanged(){
            axios.patch(`https://api.varsanpr.com/api/account/changeclient`, {
                client_id: this.$store.state.auth.user.selectedClient
            }, {
                headers: authHeader()
            })
            .then(response => {
                console.log(`Client has changed. New Client is: #${this.$store.state.auth.user.selectedClient} ${this.user.clients.filter((c) => c.id === this.$store.state.auth.user.selectedClient)[0].name}`);
                this.$store.dispatch('auth/selectClient', this.user.clients.filter((c) => c.id === this.$store.state.auth.user.selectedClient)[0]).then(() => {
                    console.log(this.$router.currentRoute.value);
                    this.$router.push({name: this.$router.currentRoute.value.name}).catch(() => {});
                    this.$router.go();
                })
            })
            .catch(error => {
                console.log(error);
                this.$error("Failed to update selected client");
            });
        },
        logout(){
            axios.post(`https://api.varsanpr.com/api/auth`, {}, {
                headers: authHeader()
            })
                .then(response => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    this.$store.commit('auth/logout');
                    console.log(this.$store.state?.auth?.user);
                    window.location.href = 'https://gateway.varsanpr.com/login?redirect=' + window.location.origin + '/login';
                })
                .catch(error => {
                    console.log(error);
                    this.$error("Failed to logout");
                });
        }
    },
    computed: {
        sortedClients: function(){
            return this.$store.state.auth.user?.clients.sort((a, b) => {
                if(a.name < b.name) return -1;
                if(a.name > b.name) return 1;
                return 0;
            }) ?? [];
        },
        phoneStatus: function(){
            if(!this.phone) return "Not Ready";
            if(this.phone && !this.phone.ready) return "Not Ready";
            if(this.phone && this.phone.ready && this.phone.onCall) return "On Call - " + this.phone.recipient;
            if(this.phone && this.phone.ready) return "Ready";
        }
    }
}
</script>

<style lang="css" scoped>
    .red-ph::placeholder {
        color: red;
    }
    .sb-closed {
        display: none;
    }

    .sb-closed-main {
        margin-left: 0px;
    }

    .sb-closed-logo {
        left: -230px;
    }

    .sb-closed-header {
        left: 0px;
    }

    .fa-bars:hover {
        color: white;
        cursor: pointer;
    }

    .phone-notes-container {
        width: 400px;
        height: 300px;
        background-color: black;
        opacity: 0.8;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 10000;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid white;
        color: white;
    }

    .phone-number-pad {
        width: 200px;
        height: 230px;
        background-color: black;
        opacity: 0.8;
        position: fixed;
        right: 400px;
        bottom: 0;
        z-index: 10000;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid white;
        color: white;
        padding: 10px;
        text-align: center;
    }

    .phone-number-pad button {
        width: 100%;
    }

    .snowflake img {
        height: 40px;
        width: 40px;
    }

    .snowflake {
        color: #fff;
        font-size: 1em;
        font-family: Serif;
        text-shadow: 0 0 1px #000;
    }
    
      @-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}
      @-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}
      @keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}
      @keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2s,1s;animation-delay:2s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1s;animation-delay:3s,1s}.snowflake:nth-of-type(10){left:99%;-webkit-animation-delay:3s,1s;animation-delay:3s,1s}
</style>
